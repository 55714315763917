<template>
  <div class="antd-panel" style="padding-top: 10px">
      <a-card :bordered="false" class="top-infor">
        <div class="title">提示：不限考试次数，通过分数{{examPassScore}}分；考试过程中可以修改完成题目的答案；如果考试异常中断，请退出并及时按同样步骤进入，继续考试</div>
        <a-row type="flex" justify="space-between" align="middle">
          <a-col type="auto">
            <a-row type="flex" :gutter="50" class="infor" align="middle">
              <a-col>
                <a-row type="flex" :gutter="3" align="middle">
                  <a-col>
                    <a-icon type="clock-circle" />
                  </a-col>
                  <a-col>
                    <a-statistic-countdown
                      :value="Date.now() + examTime * 1000"
                      @finish="onFinish"
                    />
                  </a-col>
                </a-row>
              </a-col>
              <a-col><span>共{{totalNum}}题</span></a-col>
              <a-col><span>考试总分 100分</span></a-col>
              <a-col><span>通过分数 {{examPassScore}}分</span></a-col>
            </a-row>
          </a-col>
          <a-col class="set-size">
            字号:
            <span :class="['big',{'current':setSize==='set-big'}]" @click="setSize = 'set-big'">大字</span>
            <span :class="['middle',{'current':setSize==='set-middle'}]" @click="setSize = 'set-middle'">中字</span>
            <span :class="['default',{'current':setSize==='set-default'}]" @click="setSize = 'set-default'">正常</span>
          </a-col>
        </a-row>
      </a-card>
      <a-card :bordered="false" style="border-top: 1px solid #eee">
          <a-row type="flex">
            <a-col flex="auto" style="font-size:18px;">
              <dl :class="['exam-list',setSize]">
                <template v-for="(item, index) in courseExamIndexData.questionList">
                  <dt :id="'sno-'+(index+1)" :key="index">{{item.sno}}.【{{item.questionTypeName}}】{{item.question}}</dt>
                  <dd :key="index+'o'">
                    <div v-if="item.picture"><img class="pic" :src="$imgBaseUrl + item.picture" alt=""></div>
                    <a-checkbox-group v-if="item.questionType === 2" @change="courseQuestionSubmit($event, item)" :default-value="valueToArrayFilter(item.studentAnswer)" :disabled="!timeActive">
                      <template v-for="(itemSub, indexSub) in item.items">
                        <a-checkbox :key="indexSub" :value="itemSub.itemType" :style="radioStyle">
                        {{itemSub.itemType}}：{{itemSub.itemContent}}
                        </a-checkbox>
                        <img v-if="itemSub.itemImg" :key="indexSub+'img'" class="pic-item" :src="$imgBaseUrl + itemSub.itemImg" alt="">
                      </template>
                    </a-checkbox-group>
                    <a-radio-group v-else @change="courseQuestionSubmit($event, item, 'target')" :default-value="item.studentAnswer" :disabled="!timeActive">
                      <template v-for="(itemSub, indexSub) in item.items">
                        <a-radio :key="indexSub" :value="itemSub.itemType" :style="radioStyle">
                        {{itemSub.itemType}}：{{itemSub.itemContent}}
                        </a-radio>
                        <img v-if="itemSub.itemImg" :key="indexSub+'img'" class="pic-item" :src="$imgBaseUrl + itemSub.itemImg" alt="">
                      </template>
                    </a-radio-group>
                  </dd>
                </template>
              </dl>
            </a-col>
            <a-col flex="360px">
                <div class="right-box">
                    <ul class="select-all clearfix">
                        <li>
                            <span></span>
                            <span>未答</span>
                        </li>
                        <li class="select">
                            <span></span>
                            <span>已答</span>
                        </li>
                    </ul>
                    <ul class="select-list clearfix">
                        <template v-for="(item, index) in courseExamIndexData.questionList">
                          <li :key="index" :class="{ 'select' : item.studentAnswer != null | ''}"><a :href="'#sno-'+(index+1)">{{item.sno}}</a></li>
                        </template>
                    </ul>
                    <div class="submit-exam">
                      <div>共{{totalNum}}题，剩余{{surplusNum()}}题未完成</div>
                      <div><a :class="['finish-btn',{'finish': surplusNum() === 0 }]" @click="courseLastSubmit()">交卷</a></div>
                    </div>
                </div>
            </a-col>
          </a-row>
      </a-card>
  </div>
</template>

<script>
import {getExamDetail, courseQuestionSubmit, courseLastAnswer} from '@/httpsAPI/course/index'
export default {
  name: 'Advanced',
  data () {
    return {
      courseNo:'',
      examTime:'',
      examPassScore:'',
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
        marginLeft: 0
      },
      courseExamIndexData: '',
      totalNum: '',
      setSize: 'set-default',
      finish: false,
      timeActive: true
    }
  },
  filters: {
    statusFilter (status) {
      const statusMap = {
        'agree': '成功',
        'reject': '驳回'
      }
      return statusMap[status]
    },
  },
  created(){

  },
  mounted(){
    this.courseExamIndex()
  },
  computed: {
    valueToArrayFilter: function () {
      return  (data) => {
        if(data != null){
          return data.split(',')
        }
      } 
    },
  },
  methods: {
    //交卷
    courseLastSubmit () {
      let that = this
      let data = {
        "id": this.$route.query.courseNo
      }
      console.log(data)
      if(this.surplusNum() === 0){
        courseQuestionSubmit(data).then(res=>{
          console.log(res.data,"data")
          this.timeActive = false;
          this.$confirm({
            title: '完成答卷',
            content: '你可以查看答卷详情',
            okText: '查看答卷',
            cancelText: '返回考试中心',
            onOk() {
              that.$router.push({path:'/personalDevelopment/test/examResult', query:{id: res.data.id}});
            },
            onCancel() {
              that.$router.push({path:'/personalDevelopment/test'});
            },
          });
        })
      }
    },
    surplusNum: function () {
      let num = 0
      for(let item in this.courseExamIndexData.questionList){
        if(this.courseExamIndexData.questionList[item].studentAnswer === null){
          num += 1
        }
      }
      return num
    },
    onFinish() {
      console.log('finished!');
      this.timeActive = false;
      this.courseQuestionSubmit()
    },
    // 答题
    courseQuestionSubmit (e,item,target) {
      console.log(item,"item")
      let data = {
        "id": this.$route.query.courseNo,
        "questionId": item.questionId,
        "sno": item.sno,
        "studentAnswer": e.toString()
      }
      if(target === 'target'){
        data.studentAnswer = e.target.value.toString()
      }
      if(e == ''){
        data.studentAnswer = null
      }
      if(this.timeActive){
        courseLastAnswer(data).then(res=>{
          console.log(res)
          this.courseExamIndex()
        })
      }
    },
    // 考题详情
    courseExamIndex () {
      this.courseNo = this.$route.query.courseNo
      getExamDetail(this.courseNo).then(res=>{
        console.log(res.data,"this.data")
        this.courseExamIndexData = res.data
        this.examPassScore = res.data.examPassScore
        this.examTime = res.data.surplusTime
        this.totalNum = res.data.questionList.length
      })
    },
  }
}
</script>

<style lang="less" scoped>
.exam-list{
  dt{
    margin-bottom: 10px;
    margin-top: 10px;
    padding-right: 380px;
  }
  dd{
    padding-left:30px;
    .pic{
      height:  128px;
      padding: 3px;
      border: 1px solid #efefef;
    }
    .pic-item{
      height:  128px;
      padding: 3px;
      border: 1px solid #efefef;
      margin-left: 30px;
    }
  }
}
.set-default{
  dt{
    font-size: 14px;
  }
  dd *{
    font-size: 14px;
  }
}
.set-middle{
  dt{
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  dd *{
    font-size: 16px;
  }
}
.set-big{
  dt{
    font-size: 18px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  dd *{
    font-size: 18px;
  }
}
.submit-exam{
  text-align: center;
  margin-top:50px;
  .finish-btn{
    display: inline-block;
    background-color: #ccc;
    color: #fff;
    padding: 10px 80px;
    border-radius: 20px;
    margin-top: 20px;
    cursor: text;
  }
  .finish{
    background-color: #84c562;
    color: #fff;
    cursor: pointer;
  }
}
.right-box{
    position: fixed;
    right: 60px;
    bottom: 30px;
    width: 380px;
    background-color: #fff;
    padding: 10px 24px 30px 24px;
    border: 1px solid #eee;
    border-radius: 4px;
    box-shadow: 0 0 10px #eee;
    .select-list{
      padding: 0;
        li{
            float: left;
            text-align: center;
            border-radius: 50%;
            margin: 3px;
            border: 1px #84c562 solid;
            background-color: #fff;
            cursor: pointer;
            a{
                display: inline-block;
                width: 25px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                color: #84c562;
            }
        }
        .select{
            background-color: #84c562;
            border: 1px #84c562 solid;
            a{
              color: #fff;
            }
        }
    }
    .select-all{
      padding: 0;
        li{
            float: left;
            margin: 10px;
            *{
              vertical-align:middle;
            }
            span:nth-child(1){
                display: inline-block;
                width: 10px;
                height: 10px;
                line-height: 10px;
                text-align: center;
                border-radius: 50%;
                border: 1px #84c562 solid;
                background-color: #fff;
                cursor: pointer;
            }
            span:nth-child(2){
                display: inline-block;
                line-height: 20px;
                margin-left:10px;
            }
        }
        .select{
            span:nth-child(1){
                background-color: #84c562;
                border: 1px #84c562 solid;
                color: #fff;
            }
        }
    }
}
.top-infor{
  .title{
    margin-bottom: 10px;
  }
  .infor{
    font-weight: bold;
    // color: #84c562;
    *{
      vertical-align: middle;
    }
    
    i{
      font-size: 27px;
      color:#333
    }
  }
}
.set-size{
  line-height: 32px;
  span{
    padding: 0 10px;
    cursor:pointer;
  }
  .default{
    font-size: 14px;
  }
  .middle{
    font-size: 16px;
  }
  .big{
    font-size: 18px;
  }
  .current{
    color: #84c562
  }
}
.right-box ul{
  list-style: none;
}
</style>